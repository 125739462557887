//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import eventBus from "@/plugins/eventBus";
import * as _ from "underscore";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import draggable from 'vuedraggable'
am4core.useTheme(am4themes_animated);

export default {
  components:{
    draggable
  },
  data() {
    return {
      showChart: true,
      showInnerLoading: false,
      currentVendor:{
        confJson: {
          intents: {
            goals: []
          }
        }
      },
      currentProject: {
        vendorsList: [],
      },
      AllIntentsStats: [],
      onlyIntents: [],
      isAlreadyCalling: false
    };
  },
  computed: {
    ...mapGetters({
      currentDate: "getFilterDate",
      currentAIAccount: "getCurrentAIAccount",
      currentUser: "getCurrentUser",
      darkTheme: "getDarkTheme"
    }),
    baseURl() {
      return window.location.href;
    }
  },
  methods: {
    ...mapActions({
      loadAIProject: 'loadAIProject',
      requestAIVendorList: "requestAIVendorList",
      requestIntentStatsAll: "requestIntentStatsAll",
      updateAIVendor: "updateAIVendor",
    }),
    availableIntents(){
      let currentVendorIntents = _.reject(this.AllIntentsStats , (intent) =>  intent.vendor !== this.currentVendor.openid)
      let onlyIntents =  _.pluck(currentVendorIntents , 'intent')
      this.onlyIntents =  _.compact(_.reject(onlyIntents , (intent) => _.indexOf(this.currentVendor.confJson.intents.goals, intent) !== -1))
      this.generateDataForChart()
    },
    updateVendor(vendor){
      if(_.has(vendor.confJson , 'intents')){
        if(!Array.isArray(vendor.confJson.intents.goals)){
          vendor.confJson.intents.goals = []
        }
      }else{
        vendor.confJson.intents = {}
        vendor.confJson.intents.goals = []
      }
      this.currentVendor = vendor
      if(this.currentProject.vendorsList.length > 1){
        this.$refs.vendorSelect.hidePopup()
      }
    },
    addNewGoal(){
      this.availableIntents();
      setTimeout(() => {
        this.currentVendor.confJson.intents.goals.push(this.onlyIntents[0])
        this.updateCurrentAIVendor()
      } , 100)
    },
    deleteGoal(intent , index){
      this.currentVendor.confJson.intents.goals = _.reject(this.currentVendor.confJson.intents.goals , (currentIntent) => currentIntent === intent )
      this.updateCurrentAIVendor()
    },
    updateCurrentAIVendor(){
      const that = this;
      const data = {
        projectId: that.currentProject.id,
        vendorData: that.currentVendor
      };
      that.updateAIVendor(data).then(res => {
        }).finally(() => {
        });
    },
    generateLayeredColoumnChart(chartData) {
      if (this.chart) {
        this.chart.dispose();
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      let chart = am4core.create(this.$refs.layerColunnChart, am4charts.XYChart );
      // Add percent sign to all numbers
      // Add data
      chart.data = chartData
      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "intent";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.adapter.add("text", function(text, target) {
        if(chartData.length > 3){
        return ""
        }
      });
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Goals Stats";
      valueAxis.title.fontWeight = 800;

      // Create series
      let series = chart.series.push(new am4charts.ConeSeries());
      series.dataFields.valueY = "occurance";
      series.dataFields.categoryX = "intent";
      
      series.tooltipText = `{categoryX}
      Total occurance: [bold]{valueY}`

      // let series2 = chart.series.push(new am4charts.ColumnSeries());
      // series2.dataFields.valueY = "confidanceScore";
      // series2.dataFields.categoryX = "intent";
      // series2.clustered = false;
      // series2.columns.template.width = am4core.percent(50);
      // series2.tooltipText = "Total Confidence Score  ({categoryX}): [bold]{valueY}";

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;
      this.chart = chart
    },
    loadIntentStats(data){
      let that = this
      that.showInnerLoading = false
      that.requestIntentStatsAll(data).then((response) => {
      this.AllIntentsStats = response.result
      this.availableIntents();
      }).finally(() =>{
        that.showInnerLoading = false
      })
    },
    loadCurrentProject(account){
      let that = this
      that.showInnerLoading = true
      this.loadAIProject(account).then((response) => {
        let currentProject = response
          const vendorListArr = currentProject.vendorlist.split("+");
         that.requestAIVendorList({
          projectId: account.projectId,
          list: vendorListArr
        }).then(vendor => {
          currentProject.vendorsList = vendor
          if(currentProject.vendorsList.length > 0){
            this.updateVendor(currentProject.vendorsList[0])
          }
          that.currentProject = currentProject
          setTimeout(() => {
            if(that.isAlreadyCalling === false){
              that.generateDataForChart()
            }
          }, 1000)
        }).finally(() => {
          const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: that.currentDate.start,
          enddate: that.currentDate.end,
        };
        this.loadIntentStats(data)
        })
      }).catch(err => {
      console.log('error ' , err)
      })
    },
    generateDataForChart(){
      this.isAlreadyCalling = true
      let currentVendorGoals = this.currentVendor.confJson.intents.goals
      let currentVendorIntents = _.reject(this.AllIntentsStats , (intent) =>  intent.vendor !== this.currentVendor.openid)
      let currentVendorGoalsStatus = _.filter(currentVendorIntents , (intentObj) => {
        return _.indexOf(currentVendorGoals, intentObj.intent) !== -1
      })
      let filtered_intenst = _.map(currentVendorGoalsStatus , (intentObj) => {
        return {
          intent: intentObj.intent,
          occurance: intentObj.Total.occurance,
          confidanceScore:  intentObj.Total.confidanceScore
        }
      })
      this.generateLayeredColoumnChart(filtered_intenst)
      setTimeout(() => {
        this.isAlreadyCalling = false
      }, filtered_intenst.length > 0 ? 1000 : 0);
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
        };
        this.loadIntentStats(data)
      } else {
        console.log("invalid date");
      }
    },
    onAccountUpdate(account) {
      this.loadCurrentProject({
        projectId: account.id,
        accountId: account.accountId
      })
    },
    isValid(value) {
      if (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.chart) {
          that.chart.dispose();
        }
      }
    },
    currentVendor:{
      deep: true,
      handler(newVal){
        this.generateDataForChart()
      }
    },
    darkTheme(){
      this.generateDataForChart()
    }
  },
  mounted() {
    this.loadCurrentProject({
      projectId: this.currentAIAccount.id,
      accountId: this.currentAIAccount.accountId
    })
  },
  created() {
    eventBus.$on("updateDate", this.onDateUpdate);
    eventBus.$on("updateAIAccount", this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
};
